import React, { useRef, useState, useEffect, ReactNode } from 'react';
import { createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { booksArr } from "./constants";

type Verse = {
  book: string;
  chapter: number;
  verse: number;
  text: string;
};

/* 
*
*   HELPER FUNCTIONS 
*
*/

/* #FONTs */

// bullet point
export const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

// capitalise
export const capitalise = (str: string): string => {
  const res = str.split("");
  res[0] = res[0].toUpperCase();
  return res.join("");
};

// check Book Title for Numbers (Words to Integers)
export const checkNumbers = (str: string): string => {
  const arr = str.split(" ");
  let res = "";
  switch (true) {
    case arr[0].includes("first"):
      res = `first${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("First"):
      res = `first${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("1st"):
      res = `first${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("1"):
      res = `first${capitalise(arr[1])}`;
      return res;
    case arr[0].toString().includes("1"):
      res = `first${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("second"):
      res = `second${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("Second"):
      res = `second${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("2nd"):
      res = `second${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("2"):
      res = `second${capitalise(arr[1])}`;
      return res;
    case arr[0].toString().includes("2"):
      res = `second${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("third"):
      res = `third${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("Third"):
      res = `third${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("3rd"):
      res = `third${capitalise(arr[1])}`;
      return res;
    case arr[0].includes("3"):
      res = `third${capitalise(arr[1])}`;
      return res;
    case arr[0].toString().includes("3"):
      res = `third${capitalise(arr[1])}`;
      return res;
    default:
      return arr.join(" ");
  }
};

/* EVENTs */

// Mouse
export const handleMouseHover = (
  event: React.MouseEvent,
  setHover: React.Dispatch<React.SetStateAction<{ text: string; book: string; chapter: number; verse: number } | undefined>>,
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>,
  verse: { book: string; chapter: number; verse: number }
) => {
  setHover({ text: event.currentTarget.textContent || "", ...verse });
  setIsShown(true);
};

const bookSources: { [key: string]: { [key: number]: number } } = {
  genesis: {
    1: 50
  },
  exodus: {
    1: 40
  },
  leviticus: {
    1: 27
  },
  numbers: {
      1: 36
  },
  deuteronomy: {
      1: 34
  },
  joshua: {
      1: 24
  },
  judges: {
      1: 21
  },
  ruth: {
      1: 4
  },
  firstSamuel: {
      1: 31
  },
  secondSamuel: {
      1: 24
  },
  firstKings: {
      1: 22
  },
  secondKings: {
      1: 25
  },
  firstChronicles: {
      1: 29
  },
  secondChronicles: {
      1: 36
  },
  ezra: {
      1: 10
  },
  nehemiah: {
      1: 13
  },
  esther: {
      1: 10
  },
  job: {
      1: 42
  },
  psalms: {
      1: 150
  },
  proverbs: {
      1: 31
  },
  ecclesiastes: {
      1: 12
  },
  songOfSolomon: {
      1: 8
  },
  isaiah: {
      1: 66
  },
  jeremiah: {
      1: 52
  },
  lamentations: {
      1: 5
  },
  ezekiel: {
      1: 48
  },
  daniel: {
      1: 12
  },
  hosea: {
      1: 14
  },
  joel: {
      1: 3
  },
  amos: {
      1: 9
  },
  obadiah: {
      1: 1
  },
  jonah: {
      1: 4
  },
  micah: {
      1: 7
  },
  nahum: {
      1: 3
  },
  habakkuk: {
      1: 3
  },
  zephaniah: {
      1: 3
  },
  haggai: {
      1: 2
  },
  zechariah: {
      1: 14
  },
  malachi: {
      1: 4
  },
  matthew: {
      1: 28
  },
  mark: {
      1: 16
  },
  luke: {
      1: 24
  },
  john: {
      1: 21
  },
  acts: {
      1: 28
  },
  romans: {
      1: 16
  },
  firstCorinthians: {
      1: 16
  },
  secondCorinthians: {
      1: 13
  },
  galatians: {
      1: 6
  },
  ephesians: {
      1: 6
  },
  philippians: {
      1: 4
  },
  colossians: {
      1: 4
  },
  firstThessalonians: {
      1: 5
  },
  secondThessalonians: {
      1: 3
  },
  firstTimothy: {
      1: 6
  },
  secondTimothy: {
      1: 4
  },
  titus: {
      1: 3
  },
  philemon: {
      1: 1
  },
  hebrews: {
      1: 13
  },
  james: {
      1: 5
  },
  firstPeter: {
      1: 5
  },
  secondPeter: {
      1: 3
  },
  firstJohn: {
      1: 5
  },
  secondJohn: {
      1: 1
  },
  thirdJohn: {
      1: 1
  },
  jude: {
      1: 1
  },
  revelation: {
      1: 22
  }
};

export const fetchCount = (book: string): number | string => {

  if (!book) {
    console.log("Missing book or chapter:", book );
    return "";
  }

  // Trim spaces and make sure the format is correct
  const cleanBook = book.trim().replace(/\s+/g, "-");

  if (bookSources[cleanBook]) {
      return bookSources[cleanBook][1]
  }

  return "";
}

// SearchBar
export const handleSearch = async (
  e: React.KeyboardEvent<HTMLInputElement>,
  setData: React.Dispatch<React.SetStateAction<any[]>>,
  setVerse: React.Dispatch<React.SetStateAction<any[]>>,
  searchTerm: (term: string) => void,
  setCount: React.Dispatch<React.SetStateAction<number | string>>,
  setPage: React.Dispatch<React.SetStateAction<number>>
): Promise<void> => {
  setPage(1);
  if (e.keyCode === 13) {
    const term = (e.target as HTMLInputElement).value;
    if ((term.match(/"/g) || []).length >= 2) {
      searchTerm(term);
    } else {
      const str = term.split(" ");
      let m: RegExpExecArray | null;
      let ver: string[] = [];

      if (str.length >= 1 && !str.join(" ").includes('"')) {
        const lwrCase = booksArr.map((book) => book.toLowerCase());
        const matchBook = booksArr.includes(str[0]) || lwrCase.includes(str[0]);
        const matchBookWithNumbers = checkNumbers(str.join(" ")) as string;
        const regex = new RegExp("[0-9]*:[0-9]*", "gm");

        if (matchBook) {
          while ((m = regex.exec(str.join(" "))) !== null) {
            if (m.index === regex.lastIndex) {
              regex.lastIndex++;
            }
            m.forEach((match) => {
              ver = match.split(":");
              return ver;
            });
          }
          if (ver.length > 1 && !str.join(" ").includes('"')) {
            fetchVerse(str[0].trim(), ver[0], ver[1], setData, setVerse);
            setCount(fetchCount(str[0].trim()));
            setPage(1);
          } else if (ver.length === 1 && term.endsWith(":")) {
            fetchVerse(str[0].trim(), ver[0]+":", "", setData, setVerse);
            setCount(fetchCount(str[0].trim()));
            setPage(1);
          } else if (ver.length === 1) {
            fetchVerse(str[0].trim(), ver[0]+":", "", setData, setVerse);
            setCount(fetchCount(str[0].trim()));
            setPage(1);
          } else {
            fetchVerse(str[0].trim(), 1, "", setData, setVerse);
          }
        } else if (matchBookWithNumbers) {
          while ((m = regex.exec(str.join(" "))) !== null) {
            if (m.index === regex.lastIndex) {
              regex.lastIndex++;
            }
            m.forEach((match) => {
              ver = match.split(":");
              return ver;
            });
          }
          if (ver.length > 1) {
            fetchVerse(matchBookWithNumbers.trim(), ver[0], ver[1], setData, setVerse);
            setCount(await fetchCount(matchBookWithNumbers.trim()));
            setPage(1);
          } else if (ver.length === 1) {
            fetchVerse(matchBookWithNumbers.trim(), ver[0], "", setData, setVerse);
            setCount(await fetchCount(matchBookWithNumbers.trim()));
            setPage(1);
          } else {
            fetchVerse(matchBookWithNumbers.trim(), 1, "", setData, setVerse);
            setCount(await fetchCount(matchBookWithNumbers.trim()));
            setPage(1);
          }
        } else {
          fetchVerse(str[0].trim(), 1, "", setData, setVerse);
          setCount(await fetchCount(matchBookWithNumbers.trim()));
          setPage(1);
        }
      }
    }
  }
};

/* DATA DRIVEN */

// fetch Verse
export const fetchVerse = async (
  book: string,
  chapter: string | number,
  verse: string | number,
  setData: React.Dispatch<React.SetStateAction<any[]>>,
  setVerse: React.Dispatch<React.SetStateAction<any[]>>
): Promise<void> => {

  
  const checkBook = book.split(' ')
 const bk = checkBook[0]
  try {
    await fetch(
      `https://kjvapp.com/api/${bk}/${chapter}/${verse !== "" ? Number(verse) : ""
      }`
    )
      .then((res) => res.json())
      .then(async (res) => {
        if (res.length >= 1 && res[0].text) {
          setData(res);
          setVerse(res);
        } else {
          console.log(res[0]);
        }
      });
  } catch (e) {
    console.log(e);
  }
};

/* UI */
// ANIMATION DRIVEN 
// Fade Out Section
export const FadeOutSection: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (isVisible === true) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      }, {});
      if (domRef.current) {
        observer.observe(domRef.current);
      }
      return () => {
        if (domRef.current) {
          observer.unobserve(domRef.current);
        }
      };
    }
  }, [isVisible]);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {children}
    </div>
  );
}

// Fade In Section
export const FadeInSection: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry =>
        setVisible(entry.isIntersecting)
      );
    });
    if (domRef.current) {
      observer.observe(domRef.current);
    }
    return () => {
      if (domRef.current) {
        observer.unobserve(domRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {children}
    </div>
  )
}

export let mdTheme = createTheme({ palette: { mode: "dark" } });

const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
if (darkThemeMq.matches) {
  mdTheme = createTheme({ palette: { 
    mode: "dark",
    primary: {
      main: "#FFFFFF", // Customize your primary color for dark mode
    },
    common: {
      white: "#ffffff",
      black: "#212121",
    },
    secondary: {  
      main: "#A1A1A1", // Customize your secondary color
    },
    warning: { 
      main: "#FF0000",
    },
    background: {
      default: "#121212", // Dark mode background
      paper: "#1d1d1d", // Dark paper background
    },
   } });
} else {
  mdTheme = createTheme({ palette: { 
    mode: "light",
    primary: {
      main: "#A1A1A1", // Customize your primary color
    },
    common: {
      white: "#ffffff",
      black: "#212121",
    },
    secondary: {  
      main: "#A1A1A1", // Customize your secondary color
    },
    warning: { 
      main: "#FF0000",
    },
    background: {
      default: "#ffffff", // Light mode background
      paper: "#FFFFFF", // Light paper background
    },
   } });
}
